import * as api from '@/api/track_analytic_settings.js';

export default {
	namespaced: true,
	state: {
		token:null,
		sites:[],
	},
	getters: {
		track_analytic_settings:state=>{
			return state
		},  
	},
	mutations: { 
		setData(state, data){
			state.token = data.token;
			state.sites = data.sites;  
		}, 
	},
	actions: { 
		async loadSettings({ commit }){ 
			await api.get().then((response)=>{
				if(response.result) 
					commit("setData", response.settings)  
			  })  
		}, 
		async saveToken({ dispatch }, data){ 
			let response = await api.saveToken({ token:data.token, cid:data.cid })
			if(response.result)  
				dispatch("loadSettings");
			return response;
		},  
		async addSite({ dispatch }){ 
			await api.upSite({action:"add"}).then((response)=>{
				if(response.result) 
					dispatch("loadSettings");
			  })  
		},
		async upSite({ state, dispatch }, key){ 
			let id = state.sites[key].id;
			let site = state.sites[key].site;
			let metrika_counter_id = state.sites[key].metrika_counter_id;

			await api.upSite({action:"up", id, site, metrika_counter_id}).then((response)=>{
				if(response.result) 
					dispatch("loadSettings");
			  })  
		},
		async deleteSite({ dispatch }, id){ 
			await api.deleteSite({ id }).then((response)=>{
				if(response.result) 
					dispatch("loadSettings");
			  })  
		},
	}, 
} 