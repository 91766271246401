import * as currentStatusBaseApi from '@/api/current_status_base.js';
  
export default {
	namespaced: true,
	state: {
		filter:null,
		data:null,
		view_departament:"all"	 
	},
	getters: {
		current_status_base:state=>{
			return state
		},  
	},
	mutations: {
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.data = data 
		}, 
		setVD(state, val){ 
			state.view_departament = val
		}, 
	},
	actions: {    
		async setFilterCSBase({ dispatch, rootGetters }, filter){
			let data = await currentStatusBaseApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"current_status_base", filter });	 
			if(data.result) {
				dispatch("loadDataCSBase", data.filter);	
			}				 
		},
		async loadFilterCSBase ({ commit, dispatch, rootGetters }){
			let data = await currentStatusBaseApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"current_status_base"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataCSBase", data.filter);	
			}				 
		},
		setVDCSBase({ commit, dispatch, state }, val){
			commit('setVD', val);
			dispatch("loadDataCSBase", state.filter);
		},
		async loadDataCSBase({ commit, rootGetters, state }, filter){
  
			let view_managers = [
				{
					user_id:rootGetters['account/account'].user_id,
					b_user_id:rootGetters['account/account'].bitrix.user_id,
					status:true,
				} 
			];
			let access = rootGetters['account/account'].access;

			if(access !== null)  	 
				for(let key in access){  
					if(access[key].hasOwnProperty("admin")){
						view_managers = [];
							
						if(state.view_departament == "all"){  
								
							for(let key_d in access[key].users_departament.base)    
								if(access[key].users_departament.base[key_d].length !== 0)
									for(let key_du in access[key].users_departament.base[key_d]) 
										view_managers.push(access[key].users_departament.base[key_d][key_du])
						}
						else{
							for(let key_d in access[key].users_departament.base) 	 
								if(key_d == state.view_departament && access[key].users_departament.base[key_d].length !== 0)
									view_managers = access[key].users_departament.base[key_d];							 
						}
					}
					else{

						if(
							access[key].hasOwnProperty("departament_type") && 
							access[key].departament_type == "base" && 
							access[key].hasOwnProperty("permissions")  
						)  
							for(let key_p in access[key].permissions) 
								if(
									access[key].permissions[key_p] == "see_department" ||
									access[key].permissions[key_p] == "set_plan_department" 
								)
								view_managers = access[key].users_departament 
					}
				}

			let formData = new FormData(); 
			formData.append('filter', JSON.stringify(filter)); 
			formData.append('view_managers', JSON.stringify(view_managers));  
			  
			let data = await currentStatusBaseApi.getData(formData);	 
			if(data.result) commit('setData', data);						 
		},
	}, 
}