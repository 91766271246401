import * as currentStatusCallcenterApi from '@/api/current_status_callcenter.js';
  
export default {
	namespaced: true,
	state: {
		filter:null,
		data:null,	 
		view_departament:"all",
		rating_settings:{
			show:false,
			stages_list:[],
			stages_select:[]
		}
	},
	getters: {
		current_status_callcenter:state=>{
			return state
		},  
	},
	mutations: {
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.data = data 
		}, 
		setVD(state, val){ 
			state.view_departament = val
		},
		setRatingSettings(state, data){ 
		 
			state.rating_settings.show = data.show;
			state.rating_settings.stages_list = data.stages_list;
			state.rating_settings.stages_select = data.stages_select;
		}, 
	},
	actions: {    
		async setFilterCSCallcenter({ dispatch, rootGetters }, filter){
			let data = await currentStatusCallcenterApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"current_status_callcenter", filter });	 
			if(data.result) {
				dispatch("loadDataCSCallcenter", data.filter);	
			}				 
		},
		async loadFilterCSCallcenter ({ commit, dispatch, rootGetters }){
			let data = await currentStatusCallcenterApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"current_status_callcenter"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataCSCallcenter", data.filter);	
			}				 
		}, 
		setVDCSCallcenter({ commit, dispatch, state }, val){
			commit('setVD', val);
			dispatch("loadDataCSCallcenter", state.filter);
		},
		async loadDataCSCallcenter({ commit, rootGetters, state }, filter){

			let view_managers = [
				{
					user_id:rootGetters['account/account'].user_id,
					b_user_id:rootGetters['account/account'].bitrix.user_id,
					status:true,
				} 
			];
			let access = rootGetters['account/account'].access;

			if(access !== null)  	 
				for(let key in access){  
					if(access[key].hasOwnProperty("admin")){
						view_managers = [];
							
						if(state.view_departament == "all"){  
								
							for(let key_d in access[key].users_departament.callcenter)    
								if(access[key].users_departament.callcenter[key_d].length !== 0)
									for(let key_du in access[key].users_departament.callcenter[key_d]) 
										view_managers.push(access[key].users_departament.callcenter[key_d][key_du])
						}
						else{
							for(let key_d in access[key].users_departament.callcenter) 	 
								if(key_d == state.view_departament && access[key].users_departament.callcenter[key_d].length !== 0)
									view_managers = access[key].users_departament.callcenter[key_d];							 
						}
					}
					else{

						if(
							access[key].hasOwnProperty("departament_type") && 
							access[key].departament_type == "callcenter" && 
							access[key].hasOwnProperty("permissions")  
						)  
							for(let key_p in access[key].permissions) 
								if(
									access[key].permissions[key_p] == "see_department" ||
									access[key].permissions[key_p] == "set_plan_department" 
								)
								view_managers = access[key].users_departament 
					}
				}
				
				let formData = new FormData(); 
				formData.append('filter', JSON.stringify(filter)); 
				formData.append('view_managers', JSON.stringify(view_managers)); 

				let data = await currentStatusCallcenterApi.getData(formData);	 
				if(data.result) commit('setData', data);						 
		},
		async showRatingSettings({ commit }, data){

			if(data.show)
				await currentStatusCallcenterApi.getRatingSettings().then((response)=>{
					 
					if(response.result)
						commit('setRatingSettings', { show:true, stages_list:response.stages_list, stages_select:response.stages_select });
				});	 
			else 
				commit('setRatingSettings', { show:false, stages_list:[], stages_select:[] });
			 
		},
		async saveRatingSettings({ state, dispatch }){
		 
			await currentStatusCallcenterApi.saveRatingSettings({
				stages_select:state.rating_settings.stages_select
			}).then((response)=>{
				if(response.result) 
					dispatch("snackBar/showSnackBar", "Данные обновлены!", { root: true });  
			});	 
			
		}
	}, 
}