import * as api from '@/api/track_analytic_report.js';

export default {
	namespaced: true,
	state: {
		filter:{
			comparison:false,
			items:[]
		},
		list_sources:null,
		list_site:null,
		data:[],
		data_message:null,
		load_status_source:true,
		companies:{
			show:false,
			show_card:false,
			key_card:null,
			list_companies:[],
			companies:[]
		}
	},
	getters: {
		track_analytic_report:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, data){
			state.filter = data.filter;
			state.list_sources = data.list_sources;
			state.list_site = data.list_site;
		},
		setData(state, data){
			state.data = data.data; 
			state.data_message = data.message; 
		},
		setFilterItem(state){  
			state.filter.items.push({ 
				period:null, 
				date:null,
				source_id:null,
				medium_id:null,
				campaign_id:null,
				content_id:null,
			 });
		}, 
		setFilterItemValue(state, data){
			state.filter.items[data.key][data.param] = data.value
		},
		spliceFilterItem(state, key){
			state.filter.items.splice(key, 1);
		},
		setFilterItemNullable(state){
			state.filter.items = [];
		},  
		setFilterComparison(state, val){
			state.filter.comparison = val
		},
		setShowUtm(state, data){
			if(data.type == "source")
				state.data[data.key_source].show = data.status
			if(data.type == "medium")
				state.data[data.key_source].utm_medium[data.key_medium].show = data.status
			if(data.type == "campaign")
				state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].show = data.status
			if(data.type == "content")
				state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].show = data.status
		},
		setLoadStatusSource(state, status){
			state.load_status_source = status
		},
		setShowCompanies(state, data){
			state.companies.show = data.status
			state.companies.list_companies = data.list_companies
			state.companies.companies = data.companies
		},
		setShowCardCompanies(state, data){
			state.companies.show_card = data.status
			state.companies.key_card = data.key
		},
		sort(state, data){
			 
			if(data.key_source !== null && data.key_medium == null && data.key_campaign == null && data.key_content == null){
				
				if(state.data[data.key_source].sort == null) state.data[data.key_source].sort = "desc";
					else if(state.data[data.key_source].sort == "desc") state.data[data.key_source].sort = "asc"; 
						else if(state.data[data.key_source].sort == "asc") state.data[data.key_source].sort = "desc"; 
 
				state.data[data.key_source].utm_medium.sort(function(a, b) {
					
					if(state.data[data.key_source].sort == "asc")
						return a[data.index] - b[data.index];

					if(state.data[data.key_source].sort == "desc")
						return b[data.index] - a[data.index];
				});
			}

			if(data.key_source !== null && data.key_medium !== null && data.key_campaign == null && data.key_content == null){
				
				if(state.data[data.key_source].utm_medium[data.key_medium].sort == null) state.data[data.key_source].utm_medium[data.key_medium].sort = "desc";
					else if(state.data[data.key_source].utm_medium[data.key_medium].sort == "desc") state.data[data.key_source].utm_medium[data.key_medium].sort = "asc"; 
						else if(state.data[data.key_source].utm_medium[data.key_medium].sort == "asc") state.data[data.key_source].utm_medium[data.key_medium].sort = "desc"; 
 
				state.data[data.key_source].utm_medium[data.key_medium].utm_campaign.sort(function(a, b) {
					
					if(state.data[data.key_source].utm_medium[data.key_medium].sort == "asc")
						return a[data.index] - b[data.index];

					if(state.data[data.key_source].utm_medium[data.key_medium].sort == "desc")
						return b[data.index] - a[data.index];
				});
			}

			if(data.key_source !== null && data.key_medium !== null && data.key_campaign !== null && data.key_content == null){
				
				if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == null) state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort = "desc";
					else if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == "desc") state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort = "asc"; 
						else if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == "asc") state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort = "desc"; 
 
						state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content.sort(function(a, b) {
					
					if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == "asc")
						return a[data.index] - b[data.index];

					if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == "desc")
						return b[data.index] - a[data.index];
				});
			}

			if(data.key_source !== null && data.key_medium !== null && data.key_campaign !== null && data.key_content !== null){
				
				if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == null) state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort = "desc";
					else if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == "desc") state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort = "asc"; 
						else if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == "asc") state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort = "desc"; 
 
				state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].utm_term.sort(function(a, b) {
					
					if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == "asc")
						return a[data.index] - b[data.index];

					if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == "desc")
						return b[data.index] - a[data.index];
				});
			}
		 
		}	 
	},
	actions: { 
		async loadFilter({ commit, dispatch, rootGetters }){  
			await api.getFilter({
				user_id:rootGetters['account/account'].user_id, 
				page:"track_analytics_report"
			}).then((response)=>{
				if(response.result) {
					commit('setFilter', { filter:response.filter, list_sources:response.list_sources, list_site:response.list_site });	 
					dispatch('loadTrackAnalyticsSource');
				}				 
			})	 
		}, 
		async loadTrackAnalyticsSource({ state, commit, rootGetters }){  
			commit("setLoadStatusSource", false); 
			await api.getTrackAnalyticsSource({
				filter:state.filter, 
				user_id:rootGetters['account/account'].user_id,
				page:"track_analytics_report", 
			}).then((response)=>{
				if(response.result) {
					commit('setData', { data:response.data, message:response.message });	 
					commit("setLoadStatusSource", true);
				}				 
			})	 
		}, 
		async setShowUtm({ state, commit }, data){  

			if(data.type == "source"){

				let utm_source = state.data[data.key_source].utm_id
				let site_id = state.data[data.key_source].site_id
				let key_filter_items = state.data[data.key_source].key_filter_items
				 
				await api.getTrackAnalyticsMedium({
					filter:state.filter, 
					utm_source,
					site_id,
					key_filter_items
				}).then(()=>{
					
				})
			}

			commit('setShowUtm', data);
		},
		async setShowCompanies({ state, commit }, data){
			  
			if(data.status)
				await api.getCompanies({
					filter:state.filter,
					key_source:data.key_source, 
					utm_source:data.utm_source, 
					utm_medium:data.utm_medium, 
					utm_campaign:data.utm_campaign, 
					utm_content:data.utm_content, 
					utm_term:data.utm_term
				}).then((response)=>{
					if(response.result) {
						commit("setShowCompanies", {status:data.status, list_companies:response.list_companies, companies:response.companies })
					}				 
				})
			else
				commit("setShowCompanies", {status:data.status, list_companies:[], companies:[] })
		}
	}, 
} 