import server from '@/api/http';

export async function get(params){  
	let { data } = await server.get('marketing/track_analytic/settings/get', { params });
	return data;
}
export async function saveToken(params){  
	let { data } = await server.get('marketing/track_analytic/settings/token/save', { params });
	return data;
} 
export async function upSite(params){  
	let { data } = await server.get('marketing/track_analytic/settings/site/up', { params });
	return data;
} 
export async function deleteSite(params){  
	let { data } = await server.get('marketing/track_analytic/settings/site/delete', { params });
	return data;
} 
 
 