import server from '@/api/http';

export async function shortLinkGetAll(params){  
	let { data } = await server.get('shortlink/get_all', { params });
	return data;
}

export async function shortLinkCreate(params){  
	let { data } = await server.get('shortlink/create', { params });
	return data;
}

export async function shortLinkDelete(params){  
	let { data } = await server.get('shortlink/delete', { params });
	return data;
}

 