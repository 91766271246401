import * as shortLink from '@/api/short_link.js';

export default {
	namespaced: true,
	state: {
		links:[],  
		url:null,
		page:1,
		quantity_page:null,
	},
	getters: {
		short_link:state=>{
			return state
		},  
	},
	mutations: { 
		setShortLink(state, data){ 
			state.links = data.links.data; 
			state.quantity_page = data.quantity_page; 
		},   
		setUrl(state, url){ 
			state.url = url; 
		},   
		setPage(state, page){ 
			state.page = page; 
		},   
	},
	actions: { 
		async loadShortLink({ state, commit }){ 
			await shortLink.shortLinkGetAll({ page:state.page }).then((response)=>{
				if(response.result) {
					commit('setShortLink', response.data);	 
				}				 
			})	 
		},  
		async createShortLink({ state, commit, dispatch }){ 

			let response = await shortLink.shortLinkCreate({url:state.url});
			 
			if(response.result) {
				commit('setUrl', null);	 
				dispatch("loadShortLink")
			}				 

			return response.link
		},  
		async deleteShortLink({ dispatch }, id){ 
			await shortLink.shortLinkDelete({id}).then((response)=>{
				if(response.result) {
					dispatch("loadShortLink")
				}				 
			})	 
		},  
		setPage({commit, dispatch}, page){
			commit('setPage', page);	 
			dispatch("loadShortLink")
		}
	}, 
} 